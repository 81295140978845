export const TIME = [
  {
    startTime: "9:00",
    endTime: "10:00",
  },
  {
    startTime: "10:00",
    endTime: "11:00",
  },
  {
    startTime: "11:00",
    endTime: "12:00",
  },
  {
    startTime: "12:00",
    endTime: "13:00",
  },
  {
    startTime: "14:00",
    endTime: "15:00",
  },
  {
    startTime: "15:00",
    endTime: "16:00",
  },
  {
    startTime: "16:00",
    endTime: "17:00",
  },
  {
    startTime: "17:00",
    endTime: "18:00",
  },
];
export const DISEASE_DESCRIPTION = [
  {
    name: "구진(Papule)",
    describe: "피부 표면에 나타나는 작은, 잘 정의된 돌출부로, 보통 1cm 미만의 크기를 가지며 고름이 없고 단단합니다. 구진은 둥글거나, 뾰족하거나 평평한 꼭대기를 가질 수 있으며, 종종 딱딱하거나 부드럽고, 표면이 거칠거나 매끄러울 수 있습니다. 색깔은 피부색, 빨강, 갈색, 파랑, 보라색 등 다양한 색상을 가질 수 있습니다. 구진은 다양한 원인에 의해 발생할 수 있으며, 피부 감염, 염증, 벌레 물림 등이 그 원인이 될 수 있습니다​"
  },
  {
    name: "이상 없음",
    describe: "특이사항 발견되지 않음"
  },
  {
    name: "태선화(Lichenification)",
    describe: "태선화는 피부가 두꺼워지고 거칠어지는 현상으로, 보통 만성적인 자극이나 긁힘에 의해 발생합니다. 피부가 가려워서 지속적으로 긁거나 문지르면 발생하며, 일반적으로 피부가 더 어두워지고 단단해지는 특징을 보입니다."
  },
  {
    name: "농포/여드름",
    describe: "농포는 고름이 차 있는 작은 피부 병변으로, 주로 여드름과 같은 염증성 피부질환의 결과로 나타납니다. 농포는 보통 붉은 색의 염증 부위로 둘러싸여 있으며, 표면이 하얗거나 노란색으로 보일 수 있습니다. 여드름은 모공이 막히고 세균이 증식하면서 발생하는데, 이로 인해 염증이 생기고 농포가 형성됩니다"
  },
  {
    name: '미란/궤양(Erosion)',
    describe: "피부의 표층에 국한되는 조직결손을 말한다. 치유 후에 흉터를 남기지 않고 완전히 치유된다.표피까지 얕은 수준의 궤양을 말하며 반흔을 남기지 않는다. 고름딱지나 물집이 터진 후 표피만 떨어져 나가 생기며 이곳은 딱지가 형성되거나 형성되지 않을 수도 있으나 흉터 없이 치유된다."
  },
  {
    name: '결절/종괴(Tumor)',
    describe: "종괴는 덩이나 멍울, 혹이란 말로 혼용된다.혹이 염증이 뭉친 경우가 아니라, 세포가 자라서 혹이 된 경우 라면 종양이란 표현을 쓴다.세포가 얌전하게 자라고 몸에 문제를 끼치지 않으면 양성종양, 거침없이 자라서 여기저기 퍼지면 악성종양 즉 암이다.종괴와 결절을 칼로 베듯 구분하는 명확한 기준은 없다. 다만 각 장기의 원래 크기에 비추어 가늠하기도 한다.예를 들어 피부 결절은 1cm보다 작을 때, 폐에 생기는 결절은 3cm보다 작을 때 등이다",
  },
]